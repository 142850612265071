import React,{ useState,useEffect} from "react";
import Jitsi from 'react-jitsi';

import '../assets/bootstrap/css/bootstrap.css'; 
import '../assets/css/all.css';
import '../assets/css/style.css'; 
import '../assets/css/about-us.css'; 

import Header from "../components/header";
import Auth from '../components/auth';
import Footer from '../components/footer';
import { getUser, isLoggedIn, logout } from "../helpers/auth";


const PrivacyPolicy = () => {
   
    return (
        <div>   
            <p class="c3"><span class="c4 c1">About Usw</span></p>
            <p class="c3"><span class="c1">Meetnow is an Indian based tech startup which provides a secure and reliable platform for video conferences. </span><span class="c0">&nbsp;We offer free video and audio conferencing call options on our mobile and web based platform for your personal, business and educational needs. Our team provides premium support and customization solutions to cater to your specific organizational requirements. </span></p>
            <p class="c3"><span class="c2">It&rsquo;s our goal to support individuals, businesses and educational institutions, in India and around the world, stay connected </span><span class="c6">amid the global coronavirus (COVID-19) outbreak. </span></p>
            <p class="c3"><span class="c4 c1">MeetNow for Businesses</span></p>
            <p class="c3"><span class="c2">MeetNow strives to provide easy and secure solutions to your Business conferencing needs. </span><span class="c1">Conduct your business meetings with premium quality HD video and audio connections without worrying about infrastructure scaling. </span><span class="c0">Our clients can opt to record their business calls and save data and information for security, training or audit purposes at a secured location of their choice. </span></p>
            <p class="c3"><span class="c0">We value your privacy and information security and provide end-to-end encryptions for all the communications through MeetNow. </span></p>
            <p class="c3"><span class="c1">We provide SDKs to customize MeetNow to suit your business needs and enable you to further promote your brand.</span><span class="c0">&nbsp;We aim to offer you the services you need at lower than market prices without compromising on quality.</span></p>
            <p class="c3"><span class="c1 c4">MeetNow for Education</span></p>
            <p class="c3"><span class="c1">MeetNow is focussed to provide teachers and students in India with the resources they need to schedule and structure online classrooms and participate in distance education. </span><span class="c0">We are committed to make learning a continuous and seamless experience by helping the students and teachers connect with each other from wherever they are. </span></p>
            <p class="c3"><span class="c2">We provide Web based UI for efficient teaching practices. </span><span class="c6">Teachers/ administrators can use the Web platform to schedule their classes and send out invites to students. Students, however, can join the classes from any device using these links and are not required to create a MeetNow account to join classes. Also, </span><span class="c2">teachers/ administrators can schedule and/or participate in recurring staff meetings, parent-teacher meetings by using easy one-click room access. Furthermore, teachers/ </span><span class="c6">administrators will have advanced controls for enabling/disabling recording, chat, enable/disable participant video and audio to conduct distraction-free lessons. </span></p>
            <p class="c3"><span class="c0">MeetNow&rsquo;s HD video calling platform offers a wide range of additional services including but not limited to Screen sharing, In-meeting chat, Tracking participation, Whiteboarding, Local (free) or Cloud (pay per use) recording of classes/lessons and real-time co-annotation. </span></p>
            <p class="c3"><span class="c0">We provide additional support to your specific classroom learning needs at lower than market prices without compromising on quality.</span></p>
            <p class="c5"><span class="c7"></span></p>
        </div>
    )
}
export default PrivacyPolicy